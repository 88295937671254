import { createApp } from "vue"
import { createRouter, createWebHistory } from "vue-router"
import App from "./App.vue"
import MainView from "./components/Main-View"
import SignDocumentView from "./components/Sign-Document-View"
import { createPinia } from "pinia"
import piniaPersist from "pinia-plugin-persist"

// Vuetify
import "vuetify/styles"
import { createVuetify } from "vuetify"
import * as components from "vuetify/components"
import * as directives from "vuetify/directives"
import '@mdi/font/css/materialdesignicons.css'


const routes = [
    { path: '/', component: MainView },
    { path: '/SignAgreement/:guid', component: SignDocumentView },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

const pinia = createPinia()
pinia.use(piniaPersist)

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi'
      }
  })

createApp(App)
    .use(router)
    .use(vuetify)
    .use(pinia)
    .mount('#app')