import { defineStore } from "pinia"
import axios from "axios";

const isDev = (process.env.NODE_ENV || "").trim() !== "production";

export const globalStore = defineStore("global", {
    state: () => {
        return {
            testValue: 0,
            webApi: isDev ? "http://localhost:18964/" : "/api/",
            lastApiError: null,
            trueFalseYesNo: [
                {
                    title: "Yes",
                    value: true,
                },
                {
                    title: "No",
                    value: false,
                },
            ],
            confirmTitle: null,
            confirmText: null,
            confirmOk: null,
            confirmCancel: null,
            showConfirmDialog: false,
            confirmBusy: false,
            isDirty: false
        }
    },
    actions: {
        async webApiGet(endpoint) {
            this.lastApiError = null;
            const response = await axios
                .get(
                    `${this.webApi}${endpoint}`
                )
                .catch((err) => {
                    console.log(err);
                    this.lastApiError = err;

                    if (err.response) {
                        console.log("status received");
                        /*
                         * The request was made and the server responded with a
                         * status code that falls out of the range of 2xx
                         */
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                        if (err.response.status == 401) this.logOut("Your session has expired");
                    } else if (err.request) {
                        console.log("no response received");
                        /*
                         * The request was made but no response was received, `error.request`
                         * is an instance of XMLHttpRequest in the browser and an instance
                         * of http.ClientRequest in Node.js
                         */
                        console.log(err.request);
                        this.logOut("Unable to connect to PDL web service");
                    } else {
                        // Something happened in setting up the request and triggered an Error
                        console.log('Error', err.message);
                    }
                });

            return response;
        },
        async webApiPost(endpoint, data, asForm, contentType) {
            this.lastApiError = null;

            let formOptions;

            if (asForm) {

                data = this.getFormData(data);
                formOptions = {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                }

            } else if (contentType) {
                formOptions = {
                    headers: {
                        "Content-Type": contentType,
                    }
                }

            }

            const response = await axios
                .post(`${this.webApi}${endpoint}`, data, formOptions)
                .catch((err) => {
                    console.log(err);
                    this.lastApiError = err;
                    
                    if (err.response) {
                        /*
                         * The request was made and the server responded with a
                         * status code that falls out of the range of 2xx
                         */
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                        if (err.response.status == 401) this.logOut("Your session has expired");
                    } else if (err.request) {
                        console.log("no response received");
                        /*
                         * The request was made but no response was received, `error.request`
                         * is an instance of XMLHttpRequest in the browser and an instance
                         * of http.ClientRequest in Node.js
                         */
                        console.log(err.request);
                        this.logOut("Unable to connect to PDL web service");
                    } else {
                        // Something happened in setting up the request and triggered an Error
                        console.log('Error', err.message);
                    }
                });
            return response;
        },
        async webApiPut(endpoint, data, asForm, contentType) {
            this.lastApiError = null;
            let formOptions;

            if (asForm) {

                data = this.getFormData(data);
                formOptions = {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                }

            } else if (contentType) {
                formOptions = {
                    headers: {
                        "Content-Type": contentType,
                    }
                }

            }

            const response = await axios
                .put(`${this.webApi}${endpoint}`, data, formOptions)
                .catch((err) => {
                    console.log(err);
                    this.lastApiError = err;
                    if (err.response) {
                        console.log("status received");
                        /*
                         * The request was made and the server responded with a
                         * status code that falls out of the range of 2xx
                         */
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                        if (err.response.status == 401) this.logOut("Your session has expired");
                    } else if (err.request) {
                        console.log("no response received");
                        /*
                         * The request was made but no response was received, `error.request`
                         * is an instance of XMLHttpRequest in the browser and an instance
                         * of http.ClientRequest in Node.js
                         */
                        console.log(err.request);
                        this.logOut("Unable to connect to PDL web service");
                    } else {
                        // Something happened in setting up the request and triggered an Error
                        console.log('Error', err.message);
                    }
                });
            return response;
        },
        async webApiDelete(endpoint) {
            this.lastApiError = null;
            const response = await axios
                .delete(
                    `${this.webApi}${endpoint}`
                )
                .catch((err) => {
                    console.log(err);
                    this.lastApiError = err;
                    if (err.response) {
                        console.log("status received");
                        /*
                         * The request was made and the server responded with a
                         * status code that falls out of the range of 2xx
                         */
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                        if (err.response.status == 401) this.logOut("Your session has expired");
                    } else if (err.request) {
                        console.log("no response received");
                        /*
                         * The request was made but no response was received, `error.request`
                         * is an instance of XMLHttpRequest in the browser and an instance
                         * of http.ClientRequest in Node.js
                         */
                        console.log(err.request);
                        this.logOut("Unable to connect to PDL web service");
                    } else {
                        // Something happened in setting up the request and triggered an Error
                        console.log('Error', err.message);
                    }
                });

            return response;
        },
        async webApiDownload(endpoint, fileName) {
            this.lastApiError = null;
            await axios({
                url: `${this.webApi}${endpoint}`, //your url
                method: "GET",
                responseType: "blob", // important
            }).then((response) => {
                if (!fileName) {
                    let filename = response.headers["content-disposition"].split("filename=")[1].split(".")[0];
                    let extension = response.headers["content-disposition"].split(".")[1].split(";")[0];
                    let downloadFileName = `${filename}.${extension}`;
                    if (downloadFileName) {
                        fileName = downloadFileName.replace(/"/g, "");
                    }
                }

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute("download", fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
        },
        getFormData(data, formData, parentKey) {
            var self = this;
            if (data === null || data === undefined) return null;
            formData = formData || new FormData();
            if (typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
                Object.keys(data).forEach(key =>
                    self.getFormData(data[key], formData, (!parentKey ? key : (data[key] instanceof File ? parentKey : `${parentKey}[${key}]`)))
                );
            } else {
                formData.append(parentKey, data);
            }
            return formData;
        },
        cloneObject(object) {
            return JSON.parse(JSON.stringify(object));
        },
        updateObject(obj/*, …*/) {
            for (var i = 1; i < arguments.length; i++) {
                for (var prop in arguments[i]) {
                    var val = arguments[i][prop];
                    if (typeof val == "object") // this also applies to arrays or null!
                        this.updateObject(obj[prop], val);
                    else
                        obj[prop] = val;
                }
            }
            return obj;
        },
        logOut(logoutError) {
            if(logoutError) {
                this.loginError=logoutError;
            }
            this.session = null;
        },
        showConfirm(title, text, ok, cancel) {
            this.confirmTitle = title;
            this.confirmText = text;
            this.confirmOk = ok;
            this.confirmCancel = cancel;
            this.showConfirmDialog = true;
        }
    },
    persist: {
        enabled: true
    }
})