<template>
  <v-layout class="rounded rounded-md">
    <v-main class="align-start justify-start">  
      <router-view />
    </v-main>
  </v-layout>
</template>

<script>
import { globalStore } from "@/stores/globalstore";
export default {
  name: "App",
  data() {
    return {
      global: globalStore(),
      navMinimized: false,
      activeSidebarItem: 0,
      showNewPdlDialog: false,
      newPdlData: {
        contractorId: null,
        agencyId: null,
        issue: null,
        dateOccurred: null,
        resolution: null,
        departmentId: null,
        userId: null,
        impacted: false
      },
      newPdlDepartments: [],
      newPdlUsers: [],
      isSaving: false,
    };
  },
  methods: {
    navigate(route) {
      this.$router.push(route);
    },
    
    
  },
  components: { },
  async mounted() {

  },
  
  beforeUnmount() {
  }
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.side-menu-item {
  margin-top: 15px;
  cursor: pointer;
}

html { overflow-y: auto !important; font-size: 0.9rem !important }

.logout-bar a {
  color:#fff;
  text-decoration: none;
}

.logout-bar a:hover {
  color:#fff;
  text-decoration: underline;
}

.v-field {
  font-size: 1rem !important;
}

.v-data-table-column--align-hidden {
  display: none !important;
}


.v-data-table__tr td {
  text-align: start;
}

.v-data-table__tr:hover td {
    background: rgb(250, 200, 180) !important;
  }
</style>
