<template>
This page is intentionally empty.
</template>

<script>
import { globalStore } from "@/stores/globalstore";
export default {
  name: "main-View",
  data() {
    return {
      global: globalStore(),
      itemsPerPage: 5,
      showCommentDialog: false,
      showEditDialog: false,
      commentWindowData: null,
      editWindowData: null,
      pdlDataProcesses: [],
      pdlDataRootCauses: [],
      headersAssigned: [
        { title: "Id", align: "hidden", key: "id" },
        { title: "Contractor ID", align: "start", key: "contractorId", width: "10%" },
        { title: "Agency ID", align: "start", key: "agencyId", width: "10%" },
        { title: "Issue", align: "start", key: "issue", width: "55%", sortable: false },
        { title: "Date", align: "start", key: "dateOccurred", width: "10%" },
        { title: "Contractor Impacted", align: "start", key: "impacted", width: "10%" },
        { title: "Action", align: "start", key: "action", width: "5%" },
      ],
      assigned: [],
      headersLogged: [
        { title: "Id", align: "hidden", key: "id" },
        { title: "Contractor ID", align: "start", key: "contractorId", width: "10%" },
        { title: "Agency ID", align: "start", key: "agencyId", width: "10%" },
        { title: "Issue", align: "start", key: "issue", width: "55%", sortable: false },
        { title: "Date", align: "start", key: "dateOccurred", width: "10%" },
        { title: "Contractor Impacted", align: "start", key: "impacted", width: "10%" },
        { title: "Action", align: "start", key: "action", width: "5%" },
      ],
      logged: [],
      isAssignedLoading: false,
      isLoggedLoading: false,
      pdlUpdating: false,
    };
  },
  methods: {
   
  },
  computed: {},
  async mounted() {
   
  },
  watch: {
    global: {
     
    },

  },
  components: {  },
};
</script>

<style scoped>
.pdl-container {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  height: calc(100vh - 75px);
}

.pdl-sheet {
  margin-top: 15px;
  height: 50%;
  max-height: 50%;
}

.table-container {
  height: 100%;
  overflow-y: auto;
  height: calc(100% - 2rem);
}

.pdl-sheet .header {
  font-size: 1rem !important;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  font-family: "Roboto", sans-serif !important;
  text-transform: none !important;
  background-color: #ccc;
  text-align: left;
  padding-left: 15px;
}

.v-data-table__tr td {
  text-align: start;
}

.v-data-table-column--align-hidden {
  display: none !important;
}

.comment-data {
  width: 100%;
}
</style>
