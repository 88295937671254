<template>
  <v-row no-gutters class="align-start">
    <v-col xs="12" md="5" class="col-left"></v-col>
    <v-col xs="12" md="2" class="col-right">
      <div class="og-logo">
        <svg class="top-0 left-0 w-[200px] h-[50px] block" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 200 50" style="enable-background: new 0 0 200 50" xml:space="preserve">
          <g id="Layer_2_00000005241618757412462300000018383607985820233894_">
            <g id="Layer_1-2">
              <path
                class="st0"
                d="M7.1,47.9c-0.4,0-0.7,0-1-0.1c-0.3-0.1-0.6-0.2-0.9-0.4c-0.3-0.2-0.5-0.4-0.7-0.7C4.2,46.4,4.1,46,4,45.6 c-0.2-1-0.2-2,0-3c0.1-0.4,0.2-0.7,0.4-1.1c0.2-0.3,0.4-0.5,0.7-0.7c0.3-0.2,0.6-0.3,0.9-0.4c0.3-0.1,0.7-0.1,1.1-0.1 c0.4,0,0.7,0,1.1,0.1c0.3,0.1,0.6,0.2,0.9,0.4c0.3,0.2,0.5,0.4,0.7,0.7c0.2,0.3,0.4,0.7,0.4,1.1c0.1,0.5,0.2,1,0.2,1.5 c0,0.5,0,1-0.2,1.5c-0.1,0.4-0.2,0.7-0.4,1.1C9.5,47,9.3,47.2,9,47.4c-0.3,0.2-0.6,0.3-0.9,0.4C7.8,47.9,7.4,47.9,7.1,47.9z M7.1,46.9c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.6-0.3c0.2-0.1,0.3-0.3,0.4-0.5c0.1-0.3,0.2-0.5,0.3-0.8 c0.1-0.4,0.1-0.8,0.1-1.2c0-0.4,0-0.8-0.1-1.1c-0.1-0.3-0.2-0.6-0.3-0.8c-0.1-0.2-0.3-0.4-0.4-0.5c-0.2-0.1-0.4-0.2-0.6-0.3 c-0.2-0.1-0.4-0.1-0.7-0.1c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.4,0.1-0.6,0.3c-0.2,0.1-0.3,0.3-0.4,0.5c-0.1,0.3-0.2,0.5-0.3,0.8 c-0.1,0.4-0.1,0.8-0.1,1.2c0,0.4,0,0.8,0.1,1.1c0.1,0.3,0.1,0.6,0.3,0.8c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.2,0.6,0.3 C6.6,46.9,6.9,47,7.1,46.9L7.1,46.9z"
              ></path>
              <path
                class="st0"
                d="M12.7,47.9c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.4-0.3-0.6-0.6c-0.1-0.3-0.2-0.6-0.2-0.9v-4.1h1.1v3.9 c0,0.2,0.1,0.5,0.2,0.6c0.2,0.2,0.4,0.2,0.7,0.2c0.2,0,0.3,0,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.2v-4.4h1.1v4.2 c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.1,0.1,0.2c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3-0.1l0.1,0.8c-0.1,0-0.2,0.1-0.2,0.1 c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0-0.6-0.2c-0.2-0.1-0.3-0.3-0.3-0.4c-0.2,0.1-0.4,0.2-0.6,0.3 c-0.2,0.1-0.4,0.2-0.6,0.2C13.1,47.9,12.9,47.9,12.7,47.9z"
              ></path>
              <path class="st0" d="M18.8,47.9c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.4-0.5-0.6c-0.1-0.3-0.2-0.7-0.2-1v-3h-0.9v-0.7l0.9-0.1 l0.1-1.3h1v1.3h1.7V43h-1.7v2.9c0,0.3,0,0.5,0.2,0.7c0.2,0.2,0.4,0.3,0.6,0.2c0.2,0,0.3,0,0.5-0.1c0.2,0,0.3-0.1,0.5-0.1l0.2,0.9 c-0.3,0.1-0.5,0.2-0.8,0.2C19.3,47.9,19,47.9,18.8,47.9z"></path>
              <path
                class="st0"
                d="M20.4,47.6l0.1-0.8c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0.1c0.2,0,0.3,0,0.5,0 c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.6c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.1-0.5-0.2 c-0.2-0.1-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.1-0.6-0.2c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.1-0.6 c0-0.5,0.2-0.9,0.6-1.2c0.5-0.3,1-0.5,1.6-0.4c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.3,0.1,0.5,0.1l-0.2,0.9 c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.3-0.1-0.5-0.1c-0.2,0-0.4,0-0.6,0c-0.3,0-0.6,0-0.8,0.2c-0.2,0.1-0.3,0.3-0.3,0.5 c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0,0.3,0.1,0.6,0.1c0.2,0.1,0.5,0.1,0.7,0.2 c0.2,0.1,0.4,0.2,0.6,0.3c0.1,0.1,0.3,0.3,0.3,0.5c0.2,0.4,0.2,0.9,0,1.3c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.3,0.2-0.5,0.3 c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0-0.5,0-0.7,0c-0.2,0-0.4-0.1-0.7-0.1C20.8,47.7,20.6,47.6,20.4,47.6z"
              ></path>
              <path
                class="st0"
                d="M25.1,45c0-0.4,0-0.9,0.1-1.3c0.1-0.3,0.2-0.6,0.4-0.9c0.2-0.3,0.5-0.4,0.8-0.6c0.4-0.1,0.8-0.2,1.2-0.2 c0.4,0,0.8,0.1,1.2,0.2c0.3,0.1,0.6,0.3,0.8,0.6c0.2,0.3,0.4,0.6,0.4,0.9c0.1,0.4,0.1,0.8,0.1,1.3c0,0.4,0,0.9-0.1,1.3 c-0.1,0.3-0.2,0.6-0.4,0.9c-0.2,0.3-0.5,0.4-0.8,0.6c-0.8,0.2-1.6,0.2-2.3,0c-0.3-0.1-0.6-0.3-0.8-0.6c-0.2-0.3-0.4-0.6-0.4-0.9 C25.1,45.8,25.1,45.4,25.1,45z M26.2,45c0,0.4,0,0.8,0.1,1.2c0.1,0.3,0.2,0.5,0.4,0.6c0.2,0.1,0.5,0.2,0.8,0.2 c0.3,0,0.6-0.1,0.8-0.2c0.2-0.2,0.4-0.4,0.4-0.6c0.1-0.4,0.2-0.8,0.1-1.2c0-0.4,0-0.8-0.1-1.2c-0.1-0.3-0.2-0.5-0.4-0.6 c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.2-0.4,0.4-0.4,0.6C26.3,44.2,26.2,44.6,26.2,45z"
              ></path>
              <path
                class="st0"
                d="M32.5,47.9c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.4-0.3-0.6-0.6c-0.1-0.3-0.2-0.6-0.2-0.9v-4.1h1.1v3.9 c0,0.2,0.1,0.5,0.2,0.6c0.2,0.2,0.4,0.2,0.7,0.2c0.2,0,0.3,0,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.2v-4.4h1.1v4.2 c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.1,0.1,0.2c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3-0.1l0.1,0.8c-0.1,0-0.2,0.1-0.2,0.1 c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0-0.6-0.2c-0.2-0.1-0.3-0.3-0.3-0.4c-0.2,0.1-0.4,0.2-0.6,0.3 c-0.2,0.1-0.4,0.2-0.6,0.2C33,47.9,32.7,47.9,32.5,47.9z"
              ></path>
              <path class="st0" d="M37.7,42.2v0.7c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.1,0,0.3,0,0.4,0 c0.1,0,0.3,0,0.4,0.1l-0.2,1c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.4,0c-0.2,0-0.5,0-0.7,0.1c-0.3,0.1-0.5,0.3-0.7,0.5v4.1h-1.1 v-5.6H37.7z"></path>
              <path
                class="st0"
                d="M40.3,45.1c0-0.6,0.1-1.1,0.3-1.7c0.2-0.4,0.5-0.8,0.9-1c0.5-0.2,1-0.3,1.5-0.3c0.2,0,0.4,0,0.6,0 c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.6,0.1l-0.2,0.9c-0.2,0-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5-0.1c-0.2,0-0.3,0-0.5,0 c-0.3,0-0.6,0.1-0.9,0.2c-0.2,0.2-0.4,0.4-0.6,0.7c-0.1,0.4-0.2,0.7-0.2,1.1c0,0.4,0.1,0.7,0.2,1c0.1,0.3,0.3,0.5,0.6,0.6 c0.3,0.1,0.6,0.2,0.9,0.2c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.5-0.1l0.5-0.1l0.2,0.9c-0.3,0.1-0.6,0.1-0.9,0.2c-0.3,0-0.7,0.1-1,0.1 c-0.7,0.1-1.4-0.2-1.9-0.7C40.5,46.6,40.3,45.8,40.3,45.1z"
              ></path>
              <path
                class="st0"
                d="M47.8,47.9c-0.4,0-0.7,0-1.1-0.2c-0.3-0.1-0.6-0.3-0.8-0.5c-0.2-0.2-0.4-0.5-0.5-0.9 c-0.1-0.4-0.2-0.8-0.2-1.2c0-0.5,0.1-1,0.2-1.4c0.1-0.4,0.3-0.7,0.6-0.9c0.2-0.2,0.5-0.4,0.8-0.6c0.3-0.1,0.7-0.2,1.1-0.2 c0.6-0.1,1.2,0.2,1.7,0.6c0.4,0.5,0.6,1.2,0.6,1.9c0,0,0,0.1,0,0.2s0,0.2,0,0.3l-0.6,0c-0.2,0-0.6,0-0.8,0.1l-1,0.1h-1.2 c0,0,0,0.1,0,0.1v0.1c0,0.3,0,0.7,0.2,1c0.1,0.2,0.3,0.4,0.5,0.5c0.3,0.1,0.6,0.2,0.9,0.2c0.2,0,0.4,0,0.7,0s0.4-0.1,0.7-0.1 c0.2,0,0.4-0.1,0.6-0.1l0.1,0.8c-0.2,0.1-0.4,0.1-0.7,0.2c-0.2,0.1-0.5,0.1-0.8,0.1C48.4,47.9,48.1,47.9,47.8,47.9z M46.5,44.4 c0.3,0,0.6,0,0.8,0l0.7,0l0.6,0H49c0-0.3,0-0.6-0.1-0.8c-0.1-0.2-0.2-0.4-0.4-0.5c-0.2-0.1-0.4-0.2-0.6-0.1c-0.2,0-0.3,0-0.5,0.1 c-0.2,0.1-0.3,0.1-0.4,0.2c-0.1,0.1-0.3,0.3-0.3,0.5C46.6,43.9,46.5,44.2,46.5,44.4z"
              ></path>
              <path
                class="st0"
                d="M54.5,47.8l-0.1-0.5c-0.3,0.2-0.6,0.3-0.9,0.4c-0.3,0.1-0.6,0.1-0.9,0.1c-0.6,0-1.1-0.3-1.4-0.7 c-0.4-0.6-0.5-1.3-0.5-2.1c0-0.6,0.1-1.2,0.3-1.7c0.2-0.4,0.5-0.8,0.9-1c0.4-0.2,0.9-0.3,1.4-0.3c0.2,0,0.3,0,0.6,0s0.4,0,0.6,0.1 v-2.1h1.1v7.7H54.5z M54.4,46.5V43c-0.2,0-0.4-0.1-0.6-0.1c-0.1,0-0.3,0-0.5,0c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.2-0.4,0.4-0.5,0.7 c-0.1,0.4-0.2,0.8-0.2,1.2c0,0.4,0,0.7,0.1,1.1c0.1,0.2,0.2,0.5,0.4,0.6c0.2,0.1,0.4,0.2,0.6,0.2c0.1,0,0.3,0,0.4,0 c0.1,0,0.3-0.1,0.4-0.1C54.1,46.7,54.2,46.6,54.4,46.5z"
              ></path>
              <path class="st0" d="M59.3,47.8v-7.3h4.8v1h-3.6v2h3.4v1h-3.4v2.2h3.8v1L59.3,47.8z"></path>
              <path
                class="st0"
                d="M65,47.8v-5.6h1v0.5c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.1,0.6-0.2c0.2,0,0.4-0.1,0.6-0.1 c0.2,0,0.4,0,0.6,0.1c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.2,0.6-0.2 c0.2-0.1,0.5-0.1,0.7-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.2,0.4 c0,0.2,0.1,0.3,0.1,0.5v4.2h-1.1V44c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.1-0.4-0.1 c-0.2,0-0.3,0-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.3,0.1-0.4,0.2c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2v3.9h-1.1V44 c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.3,0-0.4,0 c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.3,0.1-0.4,0.2v4.3L65,47.8z"
              ></path>
              <path
                class="st0"
                d="M73.5,50v-7.8h1v0.6c0.3-0.2,0.6-0.3,0.9-0.5c0.3-0.1,0.6-0.1,0.9-0.1c0.6,0,1.1,0.2,1.4,0.7 c0.4,0.6,0.5,1.3,0.5,2.1c0,0.6-0.1,1.2-0.3,1.7c-0.2,0.4-0.5,0.8-0.9,1c-0.4,0.2-0.9,0.3-1.4,0.3c-0.2,0-0.3,0-0.5,0l-0.6-0.1V50 L73.5,50z M75.6,47c0.3,0,0.6-0.1,0.8-0.2c0.2-0.2,0.4-0.4,0.5-0.7c0.1-0.4,0.2-0.8,0.2-1.2c0-0.4,0-0.7-0.1-1.1 c-0.1-0.2-0.2-0.5-0.4-0.6C76.4,43.1,76.2,43,76,43c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0.1-0.3,0.2-0.5,0.3v3.5 c0.2,0,0.4,0.1,0.6,0.1C75.3,47,75.5,47,75.6,47z"
              ></path>
              <path class="st0" d="M80.2,40.1v6c0,0.2,0,0.3,0.1,0.5c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.2,0 c0.1,0,0.2,0,0.3-0.1l0.1,0.9c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0-0.5,0-0.7-0.2c-0.2-0.1-0.4-0.3-0.5-0.5 c-0.1-0.3-0.2-0.7-0.2-1v-6.1H80.2z"></path>
              <path
                class="st0"
                d="M81.6,45c0-0.4,0-0.9,0.1-1.3c0.1-0.3,0.2-0.6,0.4-0.9c0.2-0.3,0.5-0.4,0.8-0.6c0.4-0.1,0.8-0.2,1.2-0.2 c0.4,0,0.8,0.1,1.2,0.2c0.3,0.1,0.6,0.3,0.8,0.6c0.2,0.3,0.4,0.6,0.4,0.9c0.1,0.4,0.1,0.8,0.1,1.3c0,0.4,0,0.9-0.1,1.3 c-0.1,0.3-0.2,0.6-0.4,0.9c-0.2,0.3-0.5,0.4-0.8,0.6c-0.8,0.2-1.6,0.2-2.3,0c-0.3-0.1-0.6-0.3-0.8-0.6c-0.2-0.3-0.4-0.6-0.4-0.9 C81.6,45.8,81.6,45.4,81.6,45z M82.7,45c0,0.4,0,0.8,0.1,1.2c0.1,0.3,0.2,0.5,0.4,0.6c0.2,0.1,0.5,0.2,0.8,0.2 c0.3,0,0.6-0.1,0.8-0.2c0.2-0.2,0.4-0.4,0.4-0.6c0.1-0.4,0.2-0.8,0.1-1.2c0-0.4,0-0.8-0.1-1.2c-0.1-0.3-0.2-0.5-0.4-0.6 c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.2-0.4,0.4-0.4,0.6C82.8,44.2,82.7,44.6,82.7,45L82.7,45z"
              ></path>
              <path class="st0" d="M88.6,49.9l0.7-2.2h-0.2L87,42.2l1.2-0.1l1.5,4.7l1.4-4.7l1.2,0L89.7,50L88.6,49.9z"></path>
              <path
                class="st0"
                d="M92.8,47.8v-5.6h1v0.5c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.1,0.6-0.2c0.2,0,0.4-0.1,0.6-0.1 c0.2,0,0.4,0,0.6,0.1c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.2,0.6-0.2 c0.2-0.1,0.5-0.1,0.7-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.2,0.4 c0,0.2,0,0.3,0,0.5v4.2h-1.1V44c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.1-0.1-0.2-0.3-0.2c-0.1-0.1-0.3-0.1-0.4-0.1 c-0.2,0-0.3,0-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.3,0.1-0.4,0.2c0,0.1,0,0.2,0.1,0.2c0,0.1,0,0.2,0,0.2v3.9h-1.1V44 c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.3,0-0.4,0 c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.3,0.1-0.4,0.2v4.3L92.8,47.8z"
              ></path>
              <path
                class="st0"
                d="M103.7,47.9c-0.4,0-0.7,0-1.1-0.2c-0.3-0.1-0.6-0.3-0.8-0.5c-0.2-0.2-0.4-0.5-0.5-0.9 c-0.1-0.4-0.2-0.8-0.2-1.2c0-0.5,0.1-1,0.2-1.4c0.1-0.4,0.3-0.7,0.6-0.9c0.2-0.2,0.5-0.4,0.8-0.6c0.3-0.1,0.7-0.2,1.1-0.2 c0.6-0.1,1.2,0.2,1.7,0.6c0.4,0.5,0.6,1.2,0.6,1.9c0,0,0,0.1,0,0.2s0,0.2,0,0.3l-0.6,0c-0.2,0-0.6,0-0.8,0.1l-1,0.1h-1.2 c0,0,0,0.1,0,0.1v0.1c0,0.3,0,0.6,0.2,0.9c0.1,0.2,0.3,0.4,0.5,0.5c0.3,0.1,0.6,0.2,0.9,0.2c0.2,0,0.4,0,0.7,0 c0.2,0,0.4-0.1,0.7-0.1s0.4-0.1,0.6-0.1l0.1,0.8c-0.2,0.1-0.4,0.1-0.7,0.2c-0.2,0.1-0.5,0.1-0.7,0.1 C104.3,47.9,104,47.9,103.7,47.9z M102.3,44.4c0.3,0,0.6,0,0.8,0l0.7,0l0.6,0h0.4c0-0.3,0-0.6-0.1-0.8c-0.1-0.2-0.2-0.4-0.4-0.5 c-0.2-0.1-0.4-0.2-0.6-0.1c-0.2,0-0.3,0-0.5,0.1c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.3,0.3-0.3,0.5 C102.4,43.9,102.3,44.2,102.3,44.4L102.3,44.4z"
              ></path>
              <path class="st0" d="M106.8,47.8v-5.6h1v0.5c0.2-0.1,0.4-0.2,0.7-0.3c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.5-0.1,0.7-0.1 c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.4,0.3,0.6,0.6c0.1,0.3,0.2,0.6,0.2,0.8v4.2h-1.1V44c0-0.3-0.1-0.5-0.2-0.7 c-0.2-0.2-0.4-0.3-0.7-0.2c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0.1-0.5,0.1c-0.2,0.1-0.3,0.1-0.5,0.2v4.4L106.8,47.8z"></path>
              <path class="st0" d="M114.4,47.9c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.4-0.5-0.6c-0.1-0.3-0.2-0.7-0.2-1v-3h-0.9v-0.7l0.9-0.1 l0.1-1.3h1v1.3h1.7V43h-1.7v2.9c0,0.3,0,0.5,0.2,0.7c0.2,0.2,0.4,0.3,0.6,0.2c0.2,0,0.3,0,0.5,0c0.2,0,0.3-0.1,0.5-0.1l0.2,0.9 c-0.3,0.1-0.5,0.2-0.8,0.2C114.8,47.9,114.6,47.9,114.4,47.9z"></path>
              <path
                class="st0"
                d="M118.7,46.4c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.4-0.4,0.6-0.6c0.3-0.1,0.7-0.2,1-0.3c0.5-0.1,1-0.1,1.5-0.2 v-0.6c0-0.3-0.1-0.5-0.3-0.6c-0.3-0.2-0.6-0.2-0.9-0.2h-0.5c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.1l-0.2-0.9 c0.3-0.1,0.6-0.2,0.9-0.2c0.3,0,0.6-0.1,0.9-0.1c0.4,0,0.9,0.1,1.3,0.2c0.3,0.1,0.6,0.3,0.7,0.6c0.2,0.3,0.3,0.7,0.2,1v2.5 c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l0.1,0.8c-0.1,0.1-0.2,0.1-0.4,0.1 c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.3-0.3-0.3-0.6c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.3 c-0.2,0.1-0.5,0.1-0.8,0.1c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.1-0.2-0.3-0.3-0.5 C118.7,46.8,118.7,46.6,118.7,46.4z M119.8,46.3c0,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.3,0.2,0.6,0.2c0.2,0,0.3,0,0.5-0.1 c0.2-0.1,0.3-0.1,0.5-0.2c0.2-0.1,0.3-0.2,0.4-0.3v-1.3c-0.3,0-0.6,0.1-0.9,0.1c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.3,0.2-0.4,0.3 C119.9,46,119.8,46.1,119.8,46.3z"
              ></path>
              <path class="st0" d="M124.3,47.8v-5.6h1v0.5c0.2-0.1,0.4-0.2,0.7-0.3c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.5-0.1,0.7-0.1 c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.4,0.3,0.6,0.6c0.1,0.3,0.2,0.6,0.2,0.8v4.2h-1.1V44c0-0.3-0.1-0.5-0.2-0.7 c-0.2-0.2-0.4-0.3-0.7-0.2c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0.1-0.5,0.1c-0.2,0.1-0.3,0.1-0.5,0.2v4.4L124.3,47.8z"></path>
              <path
                class="st0"
                d="M133.5,47.8l-0.1-0.5c-0.3,0.2-0.6,0.3-0.9,0.4c-0.3,0.1-0.6,0.1-0.9,0.1c-0.6,0-1.1-0.2-1.4-0.7 c-0.4-0.6-0.5-1.3-0.5-2.1c0-0.6,0.1-1.2,0.3-1.7c0.2-0.4,0.5-0.8,0.9-1c0.4-0.2,0.9-0.3,1.4-0.3c0.2,0,0.3,0,0.6,0s0.4,0,0.6,0.1 v-2.1h1.1v7.7H133.5z M133.3,46.5V43c-0.2,0-0.4-0.1-0.6-0.1c-0.1,0-0.3,0-0.5,0c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.2-0.4,0.4-0.5,0.7 c-0.1,0.4-0.2,0.8-0.2,1.2c0,0.4,0,0.7,0.1,1.1c0.1,0.2,0.2,0.5,0.4,0.6c0.2,0.1,0.4,0.2,0.6,0.2c0.1,0,0.3,0,0.4,0 c0.1,0,0.3-0.1,0.4-0.1C133,46.7,133.2,46.6,133.3,46.5L133.3,46.5z"
              ></path>
              <path class="st0" d="M137.6,47.8l2.5-7.4h1.4l2.5,7.4h-1.3l-0.6-1.7h-2.7l-0.6,1.7H137.6z M139.6,45.1h2.2l-1.1-3.6L139.6,45.1z"></path>
              <path
                class="st0"
                d="M144.2,45.1c0-0.6,0.1-1.1,0.3-1.7c0.2-0.4,0.5-0.8,0.9-1c0.5-0.2,1-0.3,1.5-0.3c0.2,0,0.4,0,0.6,0 s0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.6,0.1l-0.2,0.9c-0.2,0-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5-0.1c-0.2,0-0.3,0-0.5,0 c-0.3,0-0.6,0.1-0.9,0.2c-0.2,0.2-0.4,0.4-0.6,0.7c-0.1,0.4-0.2,0.7-0.2,1.1c0,0.4,0.1,0.7,0.2,1c0.1,0.3,0.3,0.5,0.6,0.6 c0.3,0.1,0.6,0.2,0.9,0.2c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.5-0.1l0.5-0.1l0.2,0.9c-0.3,0.1-0.6,0.1-0.9,0.2c-0.3,0-0.7,0.1-1,0.1 c-0.7,0.1-1.4-0.2-1.9-0.7C144.4,46.6,144.1,45.8,144.2,45.1z"
              ></path>
              <path
                class="st0"
                d="M149.2,45.1c0-0.6,0.1-1.1,0.3-1.7c0.2-0.4,0.5-0.8,0.9-1c0.5-0.2,1-0.3,1.5-0.3c0.2,0,0.4,0,0.6,0 c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.6,0.1l-0.2,0.9c-0.2,0-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5-0.1c-0.2,0-0.3,0-0.5,0 c-0.3,0-0.6,0.1-0.9,0.2c-0.2,0.2-0.4,0.4-0.6,0.7c-0.1,0.4-0.2,0.7-0.2,1.1c0,0.4,0.1,0.7,0.2,1c0.1,0.3,0.3,0.5,0.6,0.6 c0.3,0.1,0.6,0.2,0.9,0.2c0.2,0,0.3,0,0.5,0s0.3,0,0.5-0.1l0.5-0.1l0.2,0.9c-0.3,0.1-0.6,0.1-0.9,0.2c-0.3,0-0.7,0.1-1,0.1 c-0.7,0.1-1.4-0.2-1.9-0.7C149.3,46.6,149.1,45.8,149.2,45.1z"
              ></path>
              <path
                class="st0"
                d="M154.1,45c0-0.4,0-0.9,0.1-1.3c0.1-0.3,0.2-0.6,0.4-0.9c0.2-0.2,0.5-0.4,0.8-0.6c0.8-0.3,1.6-0.3,2.4,0 c0.3,0.1,0.6,0.3,0.8,0.6c0.2,0.3,0.3,0.6,0.4,0.9c0.1,0.4,0.1,0.8,0.1,1.3c0,0.4,0,0.9-0.1,1.3c-0.1,0.3-0.2,0.6-0.4,0.9 c-0.2,0.3-0.5,0.4-0.8,0.6c-0.8,0.2-1.6,0.2-2.3,0c-0.3-0.1-0.6-0.3-0.8-0.6c-0.2-0.3-0.4-0.6-0.4-0.9 C154.2,45.8,154.1,45.4,154.1,45z M155.3,45c0,0.4,0,0.8,0.1,1.2c0.1,0.3,0.2,0.5,0.4,0.6c0.2,0.1,0.5,0.2,0.8,0.2 c0.3,0,0.6-0.1,0.8-0.2c0.2-0.2,0.4-0.4,0.4-0.6c0.1-0.4,0.2-0.8,0.1-1.2c0-0.4,0-0.8-0.1-1.2c-0.1-0.3-0.2-0.5-0.4-0.6 c-0.5-0.3-1.1-0.3-1.7,0c-0.2,0.1-0.4,0.4-0.4,0.6C155.3,44.2,155.3,44.6,155.3,45z"
              ></path>
              <path
                class="st0"
                d="M161.6,47.9c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.4-0.3-0.6-0.6c-0.1-0.3-0.2-0.6-0.2-0.9v-4.1h1.1v3.9 c0,0.2,0.1,0.5,0.2,0.6c0.2,0.2,0.4,0.2,0.7,0.2c0.2,0,0.3,0,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.2-0.1,0.3-0.1,0.4-0.2v-4.4h1.1v4.2 c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.1,0.1,0.2c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3-0.1l0.1,0.8c-0.1,0-0.2,0.1-0.2,0.1 c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.3-0.3-0.3-0.4c-0.2,0.1-0.4,0.2-0.6,0.3 c-0.2,0.1-0.4,0.2-0.6,0.2C162,47.9,161.8,47.9,161.6,47.9z"
              ></path>
              <path class="st0" d="M165.7,47.8v-5.6h1v0.5c0.2-0.1,0.4-0.2,0.7-0.3c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.5-0.1,0.7-0.1 c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.4,0.3,0.6,0.6c0.1,0.3,0.2,0.6,0.2,0.8v4.2h-1.1V44c0-0.3-0.1-0.5-0.2-0.7 c-0.2-0.2-0.4-0.3-0.7-0.2c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0.1-0.5,0.1c-0.2,0.1-0.3,0.1-0.5,0.2v4.4L165.7,47.8z"></path>
              <path class="st0" d="M173.3,47.9c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.4-0.5-0.6c-0.1-0.3-0.2-0.7-0.2-1v-3h-0.9v-0.7l0.9-0.1 l0.1-1.3h1v1.3h1.7V43h-1.7v2.9c0,0.3,0,0.5,0.2,0.7c0.2,0.2,0.4,0.3,0.6,0.2c0.2,0,0.3,0,0.5,0c0.2,0,0.3-0.1,0.5-0.1l0.2,0.9 c-0.3,0.1-0.5,0.2-0.8,0.2C173.7,47.9,173.5,47.9,173.3,47.9z"></path>
              <path
                class="st0"
                d="M174.9,46.4c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.4-0.4,0.6-0.6c0.3-0.1,0.7-0.2,1-0.3c0.5-0.1,1-0.1,1.5-0.2 v-0.6c0-0.3-0.1-0.5-0.3-0.6c-0.3-0.2-0.6-0.2-0.9-0.2h-0.5c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.1l-0.2-0.9 c0.3-0.1,0.6-0.2,0.9-0.2c0.3,0,0.6-0.1,0.9-0.1c0.4,0,0.9,0.1,1.3,0.2c0.3,0.1,0.6,0.3,0.7,0.6c0.2,0.3,0.3,0.7,0.2,1v2.5 c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l0.1,0.8c-0.1,0.1-0.2,0.1-0.4,0.1 c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.3-0.3-0.3-0.6c-0.2,0.1-0.4,0.3-0.6,0.4c-0.4,0.3-0.9,0.4-1.4,0.4 c-0.2,0-0.4,0-0.6-0.1c-0.4-0.1-0.6-0.4-0.8-0.8C175,46.8,174.9,46.6,174.9,46.4z M176.1,46.3c0,0.2,0.1,0.4,0.2,0.6 c0.1,0.1,0.3,0.2,0.6,0.2c0.2,0,0.3,0,0.5-0.1c0.2-0.1,0.3-0.1,0.5-0.2c0.2-0.1,0.3-0.2,0.4-0.3v-1.3c-0.3,0-0.6,0.1-0.9,0.1 c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.3,0.2-0.4,0.3C176.1,46,176.1,46.1,176.1,46.3L176.1,46.3z"
              ></path>
              <path class="st0" d="M180.6,47.8v-5.6h1v0.5c0.2-0.1,0.4-0.2,0.7-0.3c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.5-0.1,0.7-0.1 c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.4,0.3,0.6,0.6c0.1,0.3,0.2,0.6,0.2,0.8v4.2H184V44c0-0.3-0.1-0.5-0.2-0.7 c-0.2-0.2-0.4-0.3-0.7-0.2c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0.1-0.5,0.1c-0.2,0.1-0.3,0.1-0.5,0.2v4.4L180.6,47.8z"></path>
              <path
                class="st0"
                d="M185.9,45.1c0-0.6,0.1-1.1,0.3-1.7c0.2-0.4,0.5-0.8,0.9-1c0.5-0.2,1-0.3,1.5-0.3c0.2,0,0.4,0,0.6,0 c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.6,0.1l-0.2,0.9c-0.2,0-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5-0.1c-0.2,0-0.3,0-0.5,0 c-0.3,0-0.6,0.1-0.9,0.2c-0.2,0.2-0.4,0.4-0.6,0.7c-0.1,0.4-0.2,0.7-0.2,1.1c0,0.4,0.1,0.7,0.2,1c0.1,0.3,0.3,0.5,0.6,0.6 c0.3,0.1,0.6,0.2,0.9,0.2c0.2,0,0.3,0,0.5,0s0.3,0,0.5-0.1l0.5-0.1l0.2,0.9c-0.3,0.1-0.6,0.1-0.9,0.2c-0.3,0-0.7,0.1-1,0.1 c-0.7,0.1-1.4-0.2-1.9-0.7C186.1,46.6,185.9,45.8,185.9,45.1z"
              ></path>
              <path class="st0" d="M192.3,49.9l0.7-2.2h-0.2l-2.1-5.6l1.2-0.1l1.5,4.7l1.4-4.7l1.2,0l-2.6,7.8L192.3,49.9z"></path>
              <path class="st0" d="M14.2,32C7.4,32,2.2,26.6,2.2,19.5S7.4,7,14.2,7S26,12.4,26,19.5S20.9,32,14.2,32z M14.2,10.3 c-5.6,0-8.7,4.7-8.7,9.2s3,9.2,8.7,9.2s8.6-4.7,8.6-9.2S19.8,10.3,14.2,10.3L14.2,10.3z"></path>
              <path class="st0" d="M27,31.6v-8.9c0-5.9,3.4-9.2,9.2-9.2h0.7v3.2h-0.7c-4,0-6,2-6,6v8.9H27z"></path>
              <path class="st0" d="M44.7,32c-4.8,0-8.6-4-8.6-9.2c0-5.3,3.7-9.2,8.7-9.2s8.8,3.9,8.8,9.2v8.9h-3.3v-2.1 C48.9,31.1,46.9,32,44.7,32z M44.9,16.8c-2.6,0-5.5,1.9-5.5,6c0,3.4,2.4,6,5.5,6c3.1,0,5.5-2.6,5.5-6 C50.4,19.8,48.7,16.8,44.9,16.8L44.9,16.8z"></path>
              <path class="st0" d="M68.1,31.6v-8.9c0-3.5-2-6-4.8-6c-2.9,0-4.9,2.4-4.9,6v8.9h-3.2v-8.9c0-5.2,3.5-9.2,8.1-9.2c4.6,0,8,4,8,9.2 v8.9H68.1z"></path>
              <path class="st0" d="M125,32c-6.8,0-11.7-5.2-11.7-12.5S118,7,124.5,7c4.8,0,8.8,2.8,10.7,7.5l0.4,1h-3.5l-0.2-0.4 c-1.4-3.1-4.1-5-7.4-5c-4.8,0-8.1,3.8-8.1,9.2s3.6,9.3,8.5,9.3c4.6,0,7.9-2.8,8.5-7.3h-7.2c-0.9,0-1.6-0.7-1.6-1.6l0,0 c0-0.9,0.7-1.6,1.6-1.6h10.4l0.1,0.7c0,0.4,0,0.7,0,1.1C136.7,27.2,132,32,125,32z"></path>
              <path class="st0" d="M169.7,31.6v-8.9c0-3.5-2-6-4.8-6c-2.8,0-4.9,2.4-4.9,6v8.9h-3.2v-8.9c0-5.2,3.5-9.2,8.1-9.2c4.6,0,8,4,8,9.2 v8.9H169.7z"></path>
              <path class="st0" d="M175.2,31.6V15c0-0.9,0.8-1.5,1.7-1.5l0,0c0.8,0,1.5,0.6,1.6,1.4v16.6c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0 L175.2,31.6C175.3,31.6,175.2,31.6,175.2,31.6z"></path>
              <path class="st0" d="M107.1,24.1h-14c0.5,2.9,2.5,4.8,5.4,4.8c2.2,0,4.2-1.3,5-3.3h3.4l0,0.1c-1.1,3.8-4.5,6.3-8.4,6.3 c-5.1,0-8.8-3.9-8.8-9.2s3.7-9.2,8.8-9.2s8.7,3.8,8.7,8.9c0,0.3,0,0.6-0.1,0.9L107.1,24.1z M104,21.4c-0.3-2.8-2.6-4.9-5.4-4.8 c-2.9,0-5,1.9-5.4,4.8H104z"></path>
              <path class="st0" d="M146.7,13.5c-5.1,0-8.8,3.9-8.8,9.2c0,1,0.1,1.9,0.4,2.8c1,3.8,4.4,6.5,8.4,6.4c3.9,0.1,7.4-2.5,8.4-6.3 l0-0.1h-3.4c-0.8,2-2.8,3.3-5,3.3c-2.2,0.1-4.1-1.3-4.9-3.3c-0.2-0.5-0.3-1-0.4-1.5h14l0.1-0.7c0-0.3,0.1-0.6,0.1-0.9 C155.4,17.3,151.7,13.5,146.7,13.5z M141.3,21.4c0.5-3,2.5-4.8,5.4-4.8c2.8-0.1,5.2,2,5.4,4.8H141.3z"></path>
              <path class="st0" d="M188.9,13.5c-5.1,0-8.8,3.9-8.8,9.2c0,1,0.1,1.9,0.4,2.8c1,3.8,4.4,6.4,8.4,6.4c3.9,0.1,7.4-2.5,8.4-6.3 l0-0.1h-3.4c-0.8,2-2.8,3.3-5,3.3c-2.2,0-4.1-1.3-4.9-3.3c-0.2-0.5-0.3-1-0.4-1.5h14l0.1-0.7c0-0.3,0.1-0.6,0.1-0.9 C197.6,17.3,194,13.5,188.9,13.5z M183.5,21.4c0.5-3,2.5-4.8,5.4-4.8c2.8-0.1,5.2,2,5.4,4.8H183.5z"></path>
              <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="88.4325" y1="50.5606" x2="88.4325" y2="16.5532" gradientTransform="matrix(1 0 0 -1 0 51.9614)">
                <stop offset="0" style="stop-color: #fe8011"></stop>
                <stop offset="1" style="stop-color: #fe4117"></stop>
              </linearGradient>
              <path
                class="st1"
                d="M103.3,3.3c-0.7-1.2-1.7-2.2-2.9-2.8c-2.6-1.3-5.3-0.2-7.7,0.9c-2,0.9-3.8,1.7-5.6,0.1 c1.6,2.1,3.5,1.2,5.7,0.4c2.5-0.9,5.5-1.4,7.7,0.6s1.1,5.1-1.8,5.7C94,9.4,90.7,5.1,86.2,4.8c-5.4-0.3-8.6,5.5-4.8,8.8 c-0.2,0-0.3,0-0.5,0c-4.8,0-8.1,3.3-8.1,8.1c0,1.8,0.5,3.7,1.7,5.1c-1.1,0.9-1.7,2.3-1.7,3.7c0,2.6,2,4.4,4.8,4.4h5.6 c1.6,0,2.6,0.8,2.6,2.1v0.7H89V37c-0.1-3.1-2.4-5.2-5.7-5.2h-5.5c-1.2,0-1.8-0.5-1.8-1.5c0-0.6,0.3-1.1,0.7-1.5 c1.3,0.7,2.7,1,4.2,1c4.7,0,8.1-3.4,8.1-8.1c0-4.4-3-7.7-7.3-8.1c-3.1-3.5-0.2-7.5,3.9-6.9c3.7,0.5,6.9,4.2,11.1,4.7 C101.3,11.9,105.9,8.2,103.3,3.3z M85.8,21.6c0,2.9-2,5-4.8,5c-2.9,0-4.9-2-4.9-5s2-5.1,4.9-5.1C83.7,16.6,85.8,18.7,85.8,21.6z"
              ></path>
            </g>
          </g>
        </svg>
      </div>
    </v-col>
    <v-col xs="12" md="5" class="col-right"></v-col>
  </v-row>

<div style="text-align:right;padding-right:5px;"><a href="/Pdf/EC 1 OGFS Contract of Employment.pdf" download>Download Contract of Employment</a></div>

  <div style="height: 70vh">
    <div v-if="loadingPdf" class="mt-5"><v-progress-circular indeterminate color="orange"></v-progress-circular></div>
    <vue-pdf-app v-show="!loadingPdf" v-if="pdfUrl" theme="light" :pdf="pdfUrl" :config="pdfConfig" @pages-rendered="loadingPdf=false"></vue-pdf-app>
  </div>

  <v-row no-gutters class="align-end" v-if="!loadingPdf">
    <v-col cols="12">
        <div class="checkbox-wrapper">
            <v-checkbox v-model="confirmed" label="I confirm that I have read and understood the terms of the agreement." :disabled="signed"></v-checkbox>
        </div>
    </v-col>
    <v-col cols="12">
      <v-btn color="orange-darken-3" variant="elevated" v-if="confirmed && !signed" :loading="isBusy" :disabled="buttonDisabled" class="sign-button" @click="signDocument()">Sign Agreement</v-btn>
      <div v-if="signed"><h3>Thank you for signing the agreement. You may now download a copy for your records.</h3></div>
      <div v-if="errored"><h3>An unexpected error occurred. Please contact Orange Genie for further guidance.</h3></div>
    </v-col>
  </v-row>
</template>

<script>
import VuePdfApp from "vue3-pdf-app";
import "vue3-pdf-app/dist/icons/main.css";
import { globalStore } from "@/stores/globalstore";
export default {
  name: "main-View",
  data() {
    return {
      global: globalStore(),
      guid: null,
      pdfUrl: null,
      message: "Document Signing Portal",
      confirmed: false,
      signed: false,
      errored: false,
      status: null,
      buttonDisabled: false,
      loadingPdf: true,
      pdfConfig: {
        sidebar: false,
        toolbar: {
          toolbarViewerLeft: {
            findbar: false,
            previous: true,
            next: true,
            pageNumber: true,
          },
          toolbarViewerRight: {
            presentationMode: false,
            openFile: false,
            print: false,
            download: false,
            viewBookmark: false,
          },
          toolbarViewerMiddle: {
            zoomOut: true,
            zoomIn: true,
            scaleSelectContainer: true,
          },
        },
        secondaryToolbar: false,
      },
    };
  },
  methods: {
    async init() {
      this.guid = decodeURI(this.$route.params.guid);
      this.pdfUrl = `${this.global.webApi}Pdf/Populate/${this.guid}`;
      await this.getStatus();

      if (this.status == "SIGNED") {
        this.confirmed = true;
        this.signed = true;
        this.pdfConfig.toolbar.toolbarViewerRight.download = true;
        this.pdfConfig.toolbar.toolbarViewerRight.print = true;
      }
    },
    async getStatus() {
      var response = await this.global.webApiGet(`Pdf/GetStatus/${this.guid}`);
      if (!this.global.lastApiError) {
        this.status = response.data;
        console.log("STATUS", this.status);
      } else {
        this.errored = true;
      }
    },
    async signDocument() {
      this.isBusy = true;
      this.buttonDisabled = true;
      await this.global.webApiGet(`Pdf/SendResponse/${this.guid}`);
      this.isBusy = false;
      if (!this.global.lastApiError) {
        this.signed = true;
        this.pdfConfig.toolbar.toolbarViewerRight.download = true;
        this.pdfConfig.toolbar.toolbarViewerRight.print = true;
      } else {
        this.errored = true;
      }
    },
  },
  computed: {},
  async mounted() {
    await this.init();
  },
  watch: {
    global: {},
  },
  components: { VuePdfApp },
};
</script>

<style>
.logo-container {
  width: 300px;
  margin: 0 auto;
  margin-top: 25px;
}

.st0 {
  fill: #363842;
}
.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: url(#SVGID_1_);
}

.logo-subtitle {
  margin-top: 10px;
  font-size: 1.5em;
  font-weight: bold;
}

.checkbox-wrapper {
    width:300px;
    margin: 0 auto;
}

.checkbox-wrapper .v-input__details{
   display:none;
}
</style>
